import React, { useEffect, useState } from 'react';

const LoadingPage = () => {
  const name = "muapoojarajput"; // Your name
  const [filledLetters, setFilledLetters] = useState([]);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    // Fill the name letters
    const letters = name.split('');
    letters.forEach((letter, index) => {
      setTimeout(() => {
        setFilledLetters((prev) => [...prev, letter]);
      }, index * 300); // Adjust delay between letters (300ms here)
    });

    // Countdown to 100%
    const countdownDuration = 4000; // 4 seconds
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev < 100) {
          return prev + 1; // Increase by 1
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, countdownDuration / 100); // Divide by 100 for a smooth count

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [name]);

  return (
    <div className="loading-page">
      <div className="name-container">
        <div className="loading-name">
          {name.split('').map((letter, index) => (
            <span
              key={index}
              className={`fill ${filledLetters.includes(letter) ? '' : 'hidden'}`}
              data-letter={letter}
            >
              {letter}
            </span>
          ))}
        </div>
        <div className="percentage">
          <h2>{percentage}%</h2>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
