import React, { useEffect } from 'react';
import bridalfullImg from '../assets/images/bridalfull.jpeg';
import kk from '../assets/images/kk.jpeg';
import makeup from '../assets/images/makeupss.jpg';
import '../css/bridal.css';
import Contact from './contact';
import CustomCursor from '../components/customcursor';
import BridalFooter from './bridalfooter'; // Import the footer component

const BridalServices = () => {
  const onScrollToComponent = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const componentId = hash.replace('#', '');
      const component = document.getElementById(componentId);
      if (component) {
        component.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div>
      <CustomCursor />
      <div id='bridalmain'>
      <div id="bridal">
        <div className="img-c">
          <h1>BRIDAL MAKEUP SERVICES IN GHAZIABAD, AND IN DELHI</h1>
          <img className="hero" src={bridalfullImg} alt="Bridal Makeup" />
        </div>
        <p>
          Every bride wants to look amazing on her wedding day. When the dress, shoes, and accessories are chosen, it remains only to choose a wedding makeup artist who will help emphasize your beauty.
          <br /><br />
          The perfect wedding makeup should be chosen specifically for the bride's facial features, her preferences, dress, and accessories.
          <br /><br />
          And also it is necessary to take into account such factors as the venue of the wedding because lighting can both emphasize makeup or make it dull.
        </p>
      </div>

      <div id="engagement">
        <div className="img-c">
          <img className="hero" src={kk} alt="Engagement Makeup" />
          <h1>ENGAGEMENT MAKEUP SERVICES IN GHAZIABAD, AND IN DELHI</h1>
        </div>
        <p>
          A wedding is a long-awaited, exciting day that you will remember all your life, which means that you should think over the wedding look to the smallest detail.
          <br /><br />
          On a solemn day, any bride wants to be beautiful, stylish, stunning, and also look good in wedding photos.
          <br /><br />
          Wedding makeup and hairstyle are always one of the most important aspects in creating the perfect celebration.
        </p>
      </div>

      <div id="hd">
        <div className="img-c">
          <h1>HD PARTY MAKEUP</h1>
          <img className="hero3" src={makeup} alt="HD Party Makeup" />
        </div>
        <p>
          Book your appointment today and experience the magic of makeup!
          <br />
          Ready to enhance your natural beauty?
          <br /><br />
          Book now!
        </p>
      </div>

      <div id="cont">
        <Contact />
      </div>

      
      <BridalFooter onScrollToComponent={onScrollToComponent} />
    </div>
    </div>
  );
};

export default BridalServices;
