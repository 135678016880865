import React from 'react';
import bridalFullImg from '../assets/images/bridalfull.jpeg'; // Adjust the path to your image file

const Price = () => {
  return (
    <div id='price'>
    <div id="page5">
      <h1 className="pricing">Pricing</h1>
      <br />
      <div className="p-container">
        <div className="price">
          {/* Airbrush Bridal Makeup */}
          <div className="sub-price">
            <div className="makeup-price">
              <h1>AIRBRUSH BRIDAL MAKEUP</h1>
              <p>₹ 30,000 per function</p>
            </div>
            <p>
              Latest style - Uses a small makeup air compressor, an airbrush gun, and special water-based or
              silicone-based airbrush foundation which is very thin. This allows a consistent application of foundation
              which sits evenly on the skin, &amp; it's very light weight!
            </p>
          </div>

          {/* Bridal Makeup */}
          <div className="sub-price">
            <div className="makeup-price">
              <h1>BRIDAL MAKEUP</h1>
              <p>₹ 25,000 per function</p>
            </div>
            <p>
              Traditional style of Makeup done with tools as you know &amp; use at home - brush, sponge or a duo fiber
              brush. So as you would suspect, the application of traditional foundation will be of thicker consistency.
            </p>
          </div>

          {/* HD Party Makeup */}
          <div className="sub-price">
            <div className="makeup-price">
              <h1>HD PARTY MAKEUP</h1>
              <p>₹ 5,000 per function</p>
            </div>
            <p>Usually traditional style of Makeup for your bridesmaids, family members, friends, etc.</p>
          </div>
        </div>

        {/* Image Section */}
        <div className="p-img">
          <img id="p-img" src={bridalFullImg} alt="Bridal Makeup" />
        </div>
      </div>
    </div>
    </div>
  );
};

export default Price;
