// src/pages/GalleryPage.jsx
import React from 'react';
import MakeupGallery from '../components/makeup'
import HairstyleGallery from '../components/hairstyle';

const Gallery = () => {
  return (
    <div className="gallery-container">
      <h1 className="gallery-title">My Work</h1>
      
      {/* Makeup Section */}
      <section className="makeup-section">
        <h2>Makeup</h2>
        <MakeupGallery />
      </section>
      
      {/* Hairstyle Section */}
      <section className="hairstyle-section">
        <h2>Hairstyle</h2>
        <HairstyleGallery />
      </section>
    </div>
  );
};

export default Gallery;
