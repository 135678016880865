import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './global.css';
import CustomCursor from "./components/customcursor";
import Header from "./pages/header";
import HeroSection from "./pages/herosection";
import Slider from "./pages/slidersec";
import Services from "./pages/services";
import Price from "./pages/price";
import About from "./pages/about";
import Banner from "./components/banner";
import Contact from "./pages/contact";
import Footer from "./pages/footer"; // Original footer for the main page
import BridalServices from "./pages/bridalservice";
import GoToTop from './components/backtotop';
import BridalHeader from './pages/bridalheader';
import Gallery from './pages/gallery';
import LoadingPage from './components/loading';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  const handleScrollToComponent = (componentId) => {
    const component = document.getElementById(componentId);
    if (component) {
      component.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const componentId = hash.replace('#', '');
      const component = document.getElementById(componentId);
      if (component) {
        component.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Router>
        {loading ? (
          <LoadingPage /> // Show loading page for at least 2 seconds
        ) : (
          <>
            <CustomCursor />
            <Routes>
              <Route path="/" element={
                <>
                  <Header onScrollToComponent={handleScrollToComponent} />
                  <HeroSection />
                  <Slider />
                  <Services />
                  <Price />
                  <About />
                  <Gallery />
                  <Banner />
                  <Contact />
                  <Footer onScrollToComponent={handleScrollToComponent} /> {/* Use main footer here */}
                </>
              } />
              
              <Route path="/bridalservices" element={
                <>
                  <BridalHeader onScrollToComponent={handleScrollToComponent} />
                  <BridalServices />
                </>
              } />
            </Routes>
            <GoToTop />
          </>
        )}
      </Router>
    </Suspense>
  );
}

export default App;
