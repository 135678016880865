import React from 'react';
import { Link } from 'react-router-dom';

const BridalFooter = ({ onScrollToComponent }) => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer>
      <div className="minfooter">
        <div id="logo">
          <h3 className="lll">Mua Pooja Rajput.</h3>
          <p>
            I am a professional beauty expert based in Ghaziabad and working with clients.
          </p>
        </div>
        <div className="services">
          <ul>
            <h1>Bridal Services</h1>
            <li><a href="/">Home</a></li>
            {/* Links to bridal service sections */}
            <li><Link to="/bridalservices" onClick={() => onScrollToComponent('bridal')}>Bridal Makeup</Link></li>
            <li><Link to="/bridalservices" onClick={() => onScrollToComponent('engagement')}>Engagement Makeup</Link></li>
            <li><Link to="/bridalservices" onClick={() => onScrollToComponent('hd')}>HD Party Makeup</Link></li>
            <li><Link to="/bridalservices" onClick={() => onScrollToComponent('contact')}>Contact</Link></li>
          </ul>
        </div>
        <div className="services">
          <ul>
            <h1>Contacts</h1>
            <li><a href="mailto:Muapoojarajput20@gmail.com">Email</a></li>
            <li><a href="https://www.instagram.com/muapoojarajput/">Instagram</a></li>
            <li><a href="https://www.facebook.com/profile.php?id=100063697792331">Facebook</a></li>
            <li><a href="https://www.weddingbazaar.com/bridal-makeup-artists/ghaziabad/mua-pooja-rajput">Wedding Bazar Profile</a></li>
          </ul>
        </div>
      </div>
      <div className="signature">
        <p>
          With Love By <a href="https://mukulkms.github.io/Mukulsingh/">Mukul</a> Singh
        </p>
        <p>
          &copy; {currentYear} Mua Pooja Rajput. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default BridalFooter;
