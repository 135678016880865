import React from 'react';
import '../css/button.css'; // Import the CSS file

const Button = ({ text, link }) => {
  return (
    <button type="button" className="custom-button">
      <a href={link} className="button-link">
        {text}
      </a>
    </button>
  );
};

export default Button;
