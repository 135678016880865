import React from 'react';
import bridalImg from '../assets/images/bri.jpeg';
import engagementImg from '../assets/images/enag.jpeg';
import hdPartyImg from '../assets/images/IMG_5445.JPG';
import { Link } from 'react-router-dom'; // Use Link from react-router-dom

const Services = () => {
  return (
    <div id='service'> 
    <div id="page2">
      <div className="heading">
        <h3>BRIDAL SERVICES</h3>
        <p className="p1">
          I am a professional beauty expert based in Ghaziabad and working with clients. Wedding hairstyles, makeup,
          and much more for the most spectacular events. We offer convenient on-site services to meet your bridal beauty
          needs.
        </p>
      </div>
      <div className="container2">
        <div className="cont1">
          <div className="bridal-makeup">
            <h2>Bridal Makeup</h2>
            <p className="p2">
              The perfect wedding makeup should be chosen specifically for the bride's facial features, dress, and
              accessories.
            </p>
            <Link className="a1" to="/bridalservices#bridalmain">
              Read More ↗
            </Link> {/* Link to Bridal Services */}
            <img className="makeup" src={bridalImg} alt="Bridal Makeup" />
          </div>
        </div>
        <div className="cont2">
          <div className="bridal-hair">
            <h2>Engagement Makeup</h2>
            <p className="p2">
              I will emphasize your style, focus on the merits of your appearance thanks to a professional wedding makeup.
            </p>
            <Link className="a1" to="/bridalservices#engagement">
              Read More ↗
            </Link> {/* Link to Engagement section */}
            <img className="makeup" src={engagementImg} alt="Engagement Makeup" />
          </div>
        </div>
        <div className="cont3">
          <div className="bridal-trial">
            <h2>HD Party Makeup</h2>
            <p className="p2">
              The HD party makeup serves to refine the client hairstyle and makeup so that there are no obstacles on this
              important day.
            </p>
            <Link className="a1" to="/bridalservices#hd">
              Read More ↗
            </Link> {/* Link to HD Party Makeup */}
            <img className="makeup" src={hdPartyImg} alt="HD Party Makeup" />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Services;
