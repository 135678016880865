import React, { useEffect } from 'react';
import Button from '../components/button';

const HeroSection = () => {
  useEffect(() => {
    // Function to ensure the video starts as soon as possible after the component mounts
    const desktopVideo = document.querySelector('.img1');
    const mobileVideo = document.querySelector('.mobile');

    if (desktopVideo) {
      desktopVideo.play().catch(error => {
        console.error('Desktop video failed to play', error);
      });
    }

    if (mobileVideo) {
      mobileVideo.play().catch(error => {
        console.error('Mobile video failed to play', error);
      });
    }
  }, []);

  return (
    <div id='hero'>
      <div id="page1">
        <div className="hero-container">
          {/* Desktop Video */}
          <div className="hero-img1">
            <video
              className="img1"
              src="/images/main.mp4"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
            />
            {/* Mobile Video */}
            <video
              className="mobile"
              src="/images/simple.mp4"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
            />
          </div>
          <div className="hero-img2">
            <img className="img2" src="/images/hero2.jpeg" alt="Hero2" />
            <br />
            <h1>Wedding Makeup Artists &amp; Hair Stylists in Ghaziabad</h1>
            <br />
            <p>
              I am Pooja, the owner of MUA Pooja Rajput, based in Delhi NCR. I have
              been working since 2016 and have adorned 250+ brides so far. I know
              that you know yourself better, so I am inclined to work with what your
              heart truly wants.
            </p>
            <br />
            <Button text="Book now →" link="#" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
