import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    date: '',
    occasions: '',
    no_of_makeups: 1,
    ready_time: '',
    address: '',
    makeup_type: '',
    total_amount: 0,
    advance: '',
    email: '',
    phone: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const prices = {
    basic: 3500,
    hd: 5500,
    engagement: 15000,
    bridal: 25000,
    reception: 15000,
  };

  // Get today's date and add one day
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const minDate = today.toISOString().split('T')[0]; // Format as YYYY-MM-DD

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'makeup_type') {
      setErrorMessage('');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!formData.makeup_type) {
      setErrorMessage('Please select at least one makeup option.');
      return; // Prevent form submission
    }

    const totalAmount = prices[formData.makeup_type] * formData.no_of_makeups;
    const submissionData = { ...formData, total_amount: totalAmount };

    try {
      const response = await fetch('https://formspree.io/f/mnqkokze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        setSuccessMessage('Form submitted successfully!');
        setFormData({
          name: '',
          date: '',
          occasions: '',
          no_of_makeups: 1,
          ready_time: '',
          address: '',
          makeup_type: '',
          total_amount: 0,
          advance: '',
          email: '',
          phone: '',
          message: '',
        });

        // Clear the success message after 1 minute
        setTimeout(() => {
          setSuccessMessage('');
        }, 60000); // 60000 milliseconds = 1 minute
      } else {
        setSuccessMessage('Error submitting form, please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSuccessMessage('Error submitting form, please try again.');
    }
  };

  return (
    <div id='contact'>
    <div id="page6">
      <h1>CONTACT</h1>
      <div className="contact-head">
        <p>
          For inquiries and further information, please contact us using the form below or email us.
        </p>
        <a href="mailto:Muapoojarajput20@gmail.com" target="_blank" rel="noopener noreferrer">
          Muapoojarajput20@gmail.com
        </a>
      </div>

      <div className="form">
        <form id="makeupForm" onSubmit={handleFormSubmit}>
          <div className="two-column">
            <div className="one-column">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />

              <label htmlFor="date">Date:</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                min={minDate} // Set minimum date to tomorrow
                required
              />

              <label htmlFor="occasions">Occasions:</label>
              <input
                type="text"
                id="occasions"
                name="occasions"
                value={formData.occasions}
                onChange={handleInputChange}
                required
              />

              <label htmlFor="no_of_makeups">No of makeups:</label>
              <input
                type="number"
                id="no_of_makeups"
                name="no_of_makeups"
                value={formData.no_of_makeups}
                min="1"
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="one-column">
              <label htmlFor="ready_time">Ready time:</label>
              <input
                type="text"
                id="ready_time"
                name="ready_time"
                placeholder="HH:MM"
                value={formData.ready_time}
                onChange={handleInputChange}
                required
              />

              <label htmlFor="address">Address:</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />

              <label htmlFor="makeup_type">Which type of makeup do you want:</label>
              <select
                id="makeup_type"
                name="makeup_type"
                value={formData.makeup_type}
                onChange={handleInputChange}
                required
              >
                <option value="">Select an option</option>
                <option value="basic">Basic makeup - 3500 Rs</option>
                <option value="hd">HD makeup - 5500 Rs</option>
                <option value="engagement">Engagement makeup - 15000 Rs</option>
                <option value="bridal">Bridal makeup - 25000 Rs</option>
                <option value="reception">Reception makeup - 15000 Rs</option>
              </select>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </div>
          </div>

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            pattern="[0-9]+"
            title="Please enter numbers only"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleInputChange}
            required
          />

          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <button className="btn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default Contact;
