import React from 'react';
import 'primeicons/primeicons.css'; 

const Banner = () => {
  return (
    <div className="banner">
      <h1>Come over to my Instagram</h1>
      <button type="submit">
        <a href="https://www.instagram.com/muapoojarajput/" target="_blank" rel="noopener noreferrer">
        Instagram <i className="pi pi-instagram" ></i>
        </a>
      </button>
    </div>
  );
};

export default Banner;
