import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Navigation, Pagination, Autoplay } from 'swiper'; // Import necessary modules

const slidesData = [
  {
    id: 1,
    imgSrc: '../../images/bridal.jpeg',
    caption: 'BRIDAL MAKEUP',
    number: '01',
  },
  {
    id: 2,
    imgSrc: '../../images/engagements.jpeg',
    caption: 'ENGAGEMENT MAKEUP',
    number: '02',
  },
  {
    id: 3,
    imgSrc: '../../images/reception.jpeg',
    caption: 'RECEPTION MAKEUP',
    number: '03',
  },
  {
    id: 4,
    imgSrc: '../images/ss1.jpeg',
    caption: 'RECEPTION MAKEUP',
    number: '04',
  },
  
];

const Slider = () => {
  return (
    <div className="slider-container">
      <h4>I do makeup for</h4>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={50}
        loop={true}
        autoplay={{ delay: 2000 }}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          // Define breakpoints for responsive behavior
          720: {
            slidesPerView: 3, // 3 slides for screens 720px and above
          },
          719: {
            slidesPerView: 1, // 1 slide for screens below 720px
          },
        }}
      >
        {slidesData.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className="slide">
              <img className="photo-card" src={slide.imgSrc} alt={slide.caption} />
              <div className="caption">
                <p className="p2">{slide.number}</p>
                <p className="a2">{slide.caption}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
