import React from 'react';
import poojaImg from '../assets/images/pooja.jpg'; // Adjust the path to your image
import colorImg from '../assets/images/color.jpg'; // Adjust the path to your image

const About = () => {
  return (
    <div id='about'>
    <div id="page4">
      <div className="about-sec">
        {/* Pooja Image and About Section */}
        <div className="pooja-img">
          <img className="pooja" src={poojaImg} alt="Pooja" />
          <h1 className="abt">ABOUT POOJA 👉</h1>
          <p className="brief">
            I am Pooja, the owner of MUA Pooja Rajput, based in Delhi NCR. I have been working since 2016 and have adorned 250+ brides so far. I know that you know yourself better, so I am inclined to work with what your heart truly wants. I pride myself on my quality of work and flexibility. I believe that every bride deserves to have makeup that matches her vision of the perfect bridal look and personalizes each look using our expert skills and a mix of conventional as well as new-age techniques.
          </p>
        </div>

        {/* Colorful Section */}
        <div className="colorful">
          <img className="color" src={colorImg} alt="Colorful makeup" />
        </div>
      </div>
    </div>
    </div>
  );
};

export default About;
