// src/components/MakeupGallery.jsx
import React from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const MakeupGallery = () => {
  const makeupImages = [
    '/images/s1.jpeg',
    '/images/s2.jpeg',
    '/images/s4.jpeg',
    '/images/s10.jpeg',
    '/images/s5.jpeg',
    '/images/s6.jpeg',
    '/images/s3.jpeg',
    '/images/s7.jpeg',
    '/images/s12.jpeg',
    '/images/s13.jpeg',
    '/images/s17.jpeg',
    '/images/s18.jpeg',
    '/images/s19.jpeg',
    '/images/s20.jpeg',
    '/images/s21.jpeg',
    '/images/simplebride.jpeg',
    
  ];

  return (
    <PhotoProvider>
      <div id="mywork" className="makeup-gallery">
        {makeupImages.map((src, index) => (
          <PhotoView key={index} src={src}>
            <img
              src={src}
              alt={`Makeup ${index + 1}`}
              className="gallery-img" // Use your existing CSS class
            />
          </PhotoView>
        ))}
      </div>
    </PhotoProvider>
  );
};

export default MakeupGallery;
