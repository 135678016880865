import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'primeicons/primeicons.css'; // Import PrimeIcons

const BridalHeader = ({ onScrollToComponent }) => {
  useEffect(() => {
    const menuIcon = document.getElementById('menuIcon');
    const mobileNav = document.getElementById('mobileNav');
    const bars = document.querySelectorAll('.bar');
    const mobileLinks = mobileNav.querySelectorAll('a');

    const toggleMenu = () => {
      menuIcon.classList.toggle('cross');
      mobileNav.classList.toggle('show');
      // Toggle the cross class for each bar
      bars.forEach((bar) => bar.classList.toggle('cross'));
    };

    const closeMenu = () => {
      menuIcon.classList.remove('cross');
      bars.forEach((bar) => bar.classList.remove('cross'));
      mobileNav.classList.remove('show');
    };

    menuIcon.addEventListener('click', toggleMenu);

    mobileLinks.forEach((link) => {
      link.addEventListener('click', closeMenu);
    });

    // Close the mobile menu when clicking outside
    document.addEventListener('click', (event) => {
      if (!mobileNav.contains(event.target) && !menuIcon.contains(event.target)) {
        closeMenu();
      }
    });

    return () => {
      // Cleanup event listeners when component unmounts
      menuIcon.removeEventListener('click', toggleMenu);
      mobileLinks.forEach((link) => {
        link.removeEventListener('click', closeMenu);
      });
    };
  }, []);

  return (
    <nav>
      <div id="left">
        <div className="logo">
          <Link to="/">Mua Pooja Rajput</Link>
        </div>
      </div>

      <div className="center">
        <Link to="/">Home</Link>
        <Link to="#contact" onClick={() => onScrollToComponent('contact')}>Contact</Link>
      </div>

      <div className="right">
        <a href="https://www.instagram.com/muapoojarajput/">
          <i className="pi pi-instagram" style={{ fontSize: '1.5rem' }}></i>
        </a>
        <a href="https://www.facebook.com/profile.php?id=100063697792331&mibextid=LQQJ4d">
          <i className="pi pi-facebook" style={{ fontSize: '1.5rem' }}></i>
        </a>
      </div>

      {/* Mobile Menu */}
      <div className="mobile-menu">
        <div className="menu-icon" id="menuIcon">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <div className="mobile-nav" id="mobileNav">
          <Link to="/" onClick={() => onScrollToComponent('hero')}>Home</Link>
          <Link to="#contact" onClick={() => onScrollToComponent('contact')}>Contact</Link>
          <a href="https://www.instagram.com/muapoojarajput/">Instagram</a>
          <a href="https://www.weddingbazaar.com/bridal-makeup-artists/ghaziabad/mua-pooja-rajput">
            Wedding Bazar Profile
          </a>
        </div>
      </div>
    </nav>
  );
};

export default BridalHeader;
