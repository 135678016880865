// src/components/HairstyleGallery.jsx
import React from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const HairstyleGallery = () => {
  const hairstyleImages = [
    '/images/h1.jpeg',
    '/images/h2.jpeg',
    '/images/h5.jpeg',
    '/images/h6.jpeg',
    '/images/h7.jpeg',
    '/images/h8.jpeg',
    '/images/h9.jpeg',
    '/images/h10.jpeg',
   
  ];

  return (
    <PhotoProvider>
      <div className="hairstyle-gallery">
        {hairstyleImages.map((src, index) => (
          <PhotoView key={index} src={src}>
            <img
              src={src}
              alt={`Hairstyle ${index + 1}`}
              className="gallery-img" // Use your existing CSS class
            />
          </PhotoView>
        ))}
      </div>
    </PhotoProvider>
  );
};

export default HairstyleGallery;
